import React from "react";
import { motion } from "framer-motion";
import { fadeTop, motionStep } from "./Motion";
import Featured from "./Featured";
import Section from "../Common/Section";
import ProjectCard from "./ProjectCard";
import RightSvg from "../../assets/svg/right-pattern.svg";

export const projectsData = [
  {
    id: 1,
    live: "https://objects-vanshajr.streamlit.app",
    thumbnail:
      "https://res.cloudinary.com/dnciaoigz/image/upload/v1720448613/WhatsApp_Image_2024-07-08_at_19.52.33_282e22fc_ieyuto.jpg",
    title: "Object Detection",
    description:
      "A Python GUI application which identifies objects of the COCO dataset for both live feed and images. Hosted on streamlit",
    code: "https://github.com/VanshajR/ObjectDetection",
    tech: ["Python", "CustomTkinter", "OpenCV","Streamlit"],
    featured: false,
  },
  {
    id: 2,
    thumbnail:
      "https://res.cloudinary.com/dnciaoigz/image/upload/v1710926784/Screenshot_2024-03-20_145529_wfxto8.png",
    title: "Zenith",
    description:
      "Website for a 3-day tech event organised by Backslash Computing Society, TIET, involving a cryptic hunt.",
    code: "https://github.com/VanshajR/ZenithCryptic",
    tech: ["HTML/CSS", "Express", "Node", "MongoDB"],
    featured: false,
  },
  {
    id: 3,
    thumbnail:
      "https://res.cloudinary.com/dnciaoigz/image/upload/v1720448742/Screenshot_2024-07-08_195452_qgqnfj.png",
    title: "Facial Recognition",
    description:
      "A Python application which allows for training of a TensorFlow/Keras model and detect faces in the trained dataset; works for both live feed and images.",
    code: "https://github.com/VanshajR/FacialRecognition",
    tech: ["Python", "CustomTkinter", "OpenCV", "TensorFlow"],
    featured: false,
  },
  {
    id: 4,
    thumbnail: "https://res.cloudinary.com/dnciaoigz/image/upload/v1738843961/Untitled_design_15_zwyrq2.png",
    title: "Branchify",
    description: "A published python package that creates the folder structure of whichever directory it is used in, with customizable paramters.",
    code: "https://github.com/VanshajR/Branchify",
    tech: ["pip", "Python"],
    live: "https://pypi.org/project/branchify/",
    featured: false,
  },
  {
    id: 5,
    thumbnail: "https://res.cloudinary.com/dnciaoigz/image/upload/v1710926227/appls_rtgpzb.jpg",
    title: "Linear Equations Solver",
    description: "Android app that can solve a system of linear equations upto 20 variables.",
    code: "https://github.com/VanshajR/LinearEquationSolver",
    tech: ["Java", "XML", "Android Studio"],
    featured: false,
  },
  {
    id: 6,
    thumbnail:
      "https://res.cloudinary.com/dnciaoigz/image/upload/v1738844062/Untitled_design_16_z3so3b.png",
    title: "AutoQuote",
    description:
      "A published VS Code Extension that allows for automatic insertion of quotes in the code, with similar additional features.",
    code: "https://github.com/VanshajR/AutoQuote",
    tech: ["TypeScript", "VS Code Extensions"],
    live: "https://marketplace.visualstudio.com/items?itemName=VanshajRaghuvanshi.auto-quote",
    featured: false,
  },
  {
    id: 7,
    thumbnail:
      "https://res.cloudinary.com/dnciaoigz/image/upload/v1714848671/Screenshot_2024-05-05_001753_mmi8dg.png",
    title: "Healix",
    description:
      "A Python GUI application with a robust PostgreSQL backend which allows managing patient/staff records and appointments.",
    code: "https://github.com/VanshajR/Healix",
    tech: ["Python", "CustomTkinter", "PostgreSQL", "PL/pgSQL"],
    featured: false,
  },
  
  {
    id: 8,
    thumbnail:
      "https://res.cloudinary.com/dnciaoigz/image/upload/v1736880475/Screenshot_2025-01-15_001737_fze7hg.png",
    title: "Cypher the ChatBot",
    description:
      "A rule based chatbot trained using PyTorch and NLP techniques that answers questions about me, uses neural networks. Hosted on streamlit",
    code: "https://github.com/VanshajR/ChatBot",
    live: "https://cypher-chat.streamlit.app",
    tech: ["Python", "PyTorch", "NLP(nltk)", "Streamlit"],
    featured: false,
  },
  {
    id: 9,
    thumbnail: "https://res.cloudinary.com/dnciaoigz/image/upload/v1710927181/Screenshot_2024-03-20_150236_uooysj.png",
    title: "Physics Experiments Solver ",
    description:
      "Python GUI application that solves and visualises specific experiments I had in my first year in physics.",
    code: "https://github.com/VanshajR/Physics-Experiment-Result-Visualiser",
    tech: ["Python", "Tkinter", "Matplotlib"],
    featured: false,
  },
  {
    id: 10,
    thumbnail:
      "https://res.cloudinary.com/dnciaoigz/image/upload/v1736873193/Screenshot_2025-01-14_221527_olnzal.png",
    title: "Artificial Neural Network Implementation",
    description:
      "An application that uses a trained ANN model to predict the possibility of a customer leaving or staying with a company based on a dataset. Hosted on streamlit",
    code: "https://github.com/VanshajR/ANN-Implementation",
    live: "https://ann-prac-vanshajr.streamlit.app",
    tech: ["Python", "ANN", "Tensrflow", "Streamlit"],
    featured: false,
  },
  {
    id: 11,
    thumbnail:
      "https://res.cloudinary.com/dnciaoigz/image/upload/v1736880532/Screenshot_2025-01-15_001844_tbphoh.png",
    title: "Pneumonia Detection",
    description:
      "An application that uses a trained Convolutional Neural Network to predict whether uploaded X-Ray images exhibit pneumonia, and compares the results obtained via PCA, SVM and Logistic Regression as well. Hosted on streamlit",
    code: "https://github.com/VanshajR/Pneumonia_Detection",
    live: "https://pneumonia-vanshajr.streamlit.app",
    tech: ["Python", "Tensorflow", "sklearn", "CNN", "PCA", "SVM", "Streamlit"],
    featured: false,
  },
  {
    id: 12,
    thumbnail:
      "https://res.cloudinary.com/dnciaoigz/image/upload/v1736880718/Screenshot_2025-01-15_002147_bdbzfv.png",
    title: "Movie Review Classification",
    description:
      "Movie review classification using a trained Recurrent Neural Network. Hosted on streamlit",
    code: "https://github.com/VanshajR/RNN-Review-Classification",
    live: "https://review-classify.streamlit.app",
    tech: ["Python", "RNN", "Tensorflow", "Streamlit"],
    featured: false,
  },
  {
    id: 13,
    thumbnail:
      "https://res.cloudinary.com/dnciaoigz/image/upload/v1736880661/Screenshot_2025-01-15_002053_rcecex.png",
    title: "Handwritten Digit Recognition",
    description:
      "An application that uses a trained LightGBM model to predict handwritten digits from uploaded images, and visualizes results. Hosted on streamlit",
    code: "https://github.com/VanshajR/Digit-Recognition-LightGBM",
    live: "https://vanshajr-digit-recognition-lightgbm-app-99enas.streamlit.app",
    tech: ["Python", "LightGBM", "PCA", "Matplotlib/seaborn", "Streamlit"],
    featured: false,
  },
  {
    id: 14,
    thumbnail:
      "https://res.cloudinary.com/dnciaoigz/image/upload/v1736880606/Screenshot_2025-01-15_001959_g8khbo.png",
    title: "Next Word Prediction",
    description:
      "An application trained on the Hamlet corpus using an LSTM model to predict the next word in a sentence. Hosted on streamlit",
    code: "https://github.com/VanshajR/LSTM-Next-Word",
    live: "https://vanshajr-lstm-next-word-app-yrrb4v.streamlit.app",
    tech: ["Python", "LSTM", "Tensorflow", "Streamlit"],
    featured: false,
  },
  {
    id: 15,
    thumbnail:
      "https://res.cloudinary.com/dnciaoigz/image/upload/v1739251998/Screenshot_2025-02-11_110157_in70yu.png",
    title: "ReVig and Neural Network Ciphers",
    description:
      "A streamlit app that allows for encryption and decryption of text through a modified vignette cipher and a neural network cipher that I made myself.",
    code: "https://github.com/VanshajR/Ciphers",
    live: "https://cipher-vanshajr.streamlit.app/",
    tech: ["Python", "ANN", "Tensorflow", "Streamlit"],
    featured: false,
  }
];

const Projects = () => {
  return (
    <Section
      id="projects"
      title="Projects"
      subtitle="Some of the projects I have worked on:"
      className="relative"
    >
      <div className="space-y-5 lg:space-y-10 px-5 md:px-10 lg:px-20 2xl:px-40">
        {/* Right SVG */}
        <img
          src={RightSvg}
          alt=""
          className="absolute hidden right-0 bottom-2/4 w-2/12 max-w-xs md:block"
          loading="lazy"
          height={700}
          width={320}
        />
        {projectsData
          .filter((e) => e.featured === true)
          .map((e, i) => (
            <motion.div key={i} variants={fadeTop} {...motionStep}>
              <Featured
                live={e.live}
                thumbnail={e.thumbnail}
                code={e.code}
                title={e.title}
                description={e.description}
                tech={e.tech}
                secondary={i % 2 === 0 ? false : true}
              />
            </motion.div>
          ))}
      </div>
      <div className="grid grid-cols-8 2xl:grid-cols-12 gap-6 gap-y-8 my-10 px-5 md:px-10 lg:px-20 2xl:px-40">
        {projectsData
          .filter((e) => e.featured !== true)
          .map((e, i) => (
            <ProjectCard
              live={e.live}
              thumbnail={e.thumbnail}
              code={e.code}
              title={e.title}
              description={e.description}
              tech={e.tech}
              key={i}
            />
          ))}
      </div>
      <span>
        For More Projects visit my{" "}
        <a
          href="https://github.com/VanshajR"
          style={{ fontWeight: "bold", color: "red" }}
        >
          GitHub
        </a>
      </span>
    </Section>
  );
};

export default Projects;
