// import React, { useRef, useEffect } from "react";
// import { useSwipeable } from "react-swipeable";
// import "./SkillsMarquee.css";
// import python from "../../assets/yellow/pythono.png";
// import java from "../../assets/yellow/javao.png";
// import javascript from "../../assets/yellow/javascripto.png";
// import cppo from "../../assets/yellow/cppo.png";
// import sqlo from "../../assets/yellow/sqlo.png";
// import css3 from "../../assets/yellow/csso.png";
// import awso from "../../assets/yellow/awso.png";
// import git from "../../assets/yellow/gito.png";
// import django from "../../assets/yellow/djangoo.png";
// import numpy from "../../assets/yellow/numpyo.png";
// import pandas from "../../assets/yellow/pandaso.png";
// import opencv from "../../assets/yellow/opencvo.png";
// import discord from "../../assets/yellow/discordo.png";
// import mysqlo from "../../assets/yellow/mysqlo.png";
// import postgreso from "../../assets/yellow/postgreso.png";
// import tensorflow from "../../assets/yellow/tensorflowo.png";
// import keras from "../../assets/yellow/keraso.png";
// import pytorch from "../../assets/yellow/pytorcho.png";
// import jupyter from "../../assets/yellow/jupytero.png";
// import rlang from "../../assets/yellow/rlango.png";

// const skillsData = [
//   { id: 1, image: python, title: "Python" },
//   { id: 2, image: cppo, title: "C/C++" },
//   { id: 3, image: java, title: "Java" },
//   { id: 4, image: rlang, title: "R" },
//   { id: 5, image: sqlo, title: "SQL" },
//   { id: 6, image: awso, title: "AWS EC2" },
//   { id: 7, image: css3, title: "CSS" },
//   { id: 8, image: git, title: "Git" },
//   { id: 9, image: javascript, title: "JavaScript" },
//   { id: 10, image: django, title: "Django" },
//   { id: 11, image: numpy, title: "NumPy" },
//   { id: 12, image: pandas, title: "Pandas" },
//   { id: 13, image: opencv, title: "OpenCV" },
//   { id: 14, image: discord, title: "Discord" },
//   { id: 15, image: mysqlo, title: "MySQL" },
//   { id: 16, image: postgreso, title: "PostgreSQL" },
//   { id: 17, image: tensorflow, title: "TensorFlow" },
//   { id: 17, image: keras, title: "Keras" },
//   { id: 19, image: pytorch, title: "PyTorch" },
//   { id: 20, image: jupyter, title: "Jupyter" },
// ];

// function SkillsMarquee() {
//   const marqueeContainerRef = useRef(null);

//   const swipeHandlers = useSwipeable({
//     onSwipedLeft: () => handleSwipe("left"),
//     onSwipedRight: () => handleSwipe("right"),
//     preventDefaultTouchmoveEvent: true,
//     trackMouse: true,
//   });

//   const handleSwipe = (direction) => {
//     const container = marqueeContainerRef.current;
//     if (direction === "left") {
//       container.scrollBy({ left: 200, behavior: "smooth" });
//     } else if (direction === "right") {
//       container.scrollBy({ left: -200, behavior: "smooth" });
//     }
//   };

//   const handleWheel = (event) => {
//     if (event.deltaX !== 0) {
//       event.preventDefault();
//       const container = marqueeContainerRef.current;
//       container.scrollLeft += event.deltaX;
//     }
//   };

//   useEffect(() => {
//     const container = marqueeContainerRef.current;
//     container.addEventListener("wheel", handleWheel);
//     return () => {
//       container.removeEventListener("wheel", handleWheel);
//     };
//   }, []);

//   // const handleTouchStart = (event) => {
//   //   const container = marqueeContainerRef.current;
//   //   container.scrollLeft += event.touches[0].clientX;
//   // };

//   // const handleTouchMove = (event) => {
//   //   const container = marqueeContainerRef.current;
//   //   container.scrollLeft -= event.touches[0].clientX;
//   // };

//   // const handleTouchEnd = () => {
//   //   // Handle touch end if needed
//   // };

//   // useEffect(() => {
//   //   const container = marqueeContainerRef.current;
//   //   container.addEventListener("touchstart", handleTouchStart);
//   //   container.addEventListener("touchmove", handleTouchMove);
//   //   container.addEventListener("touchend", handleTouchEnd);
//   //   return () => {
//   //     container.removeEventListener("touchstart", handleTouchStart);
//   //     container.removeEventListener("touchmove", handleTouchMove);
//   //     container.removeEventListener("touchend", handleTouchEnd);
//   //   };
//   // }, []);

//   return (
//     <div
//       {...swipeHandlers}
//       ref={marqueeContainerRef}
//       className="relative flex overflow-x-hidden marquee-container"
//     >
//       <div className="py-5 flex animate-marquee whitespace-nowrap">
//         {skillsData.map(({ id, image, title }) => (
//           <div
//             key={id}
//             className="skill--box flex justify-start p-3 shadow-lg shadow-gray-400/50 dark:shadow-black/30 rounded-xl object-cover w-[4.5rem] h-[4.5rem] md:w-20 md:h-20 dark:bg-neutral-900 bg-gray-100"
//             style={{ userSelect: "none", pointerEvents: "none" }}
//           >
//             <img
//               src={image}
//               alt={title}
//               className="w-36 h-36 md:h-44 md:w-44 object-contain group-hover:grayscale"
//               style={{ userSelect: "none", pointerEvents: "none" }}
//             />
//           </div>
//         ))}
//       </div>

//       <div className="absolute flex top-0 py-5 animate-marquee2 whitespace-nowrap">
//         {skillsData.map(({ id, image, title }) => (
//           <div
//             key={id}
//             className="skill--box flex justify-start p-3 shadow-lg shadow-gray-400/50 dark:shadow-black/30 rounded-xl object-cover w-[4.5rem] h-[4.5rem] md:w-20 md:h-20 dark:bg-neutral-900 bg-gray-100"
//             style={{ userSelect: "none", pointerEvents: "none" }}
//           >
//             <img
//               src={image}
//               alt={title}
//               className="w-36 h-36 md:h-44 md:w-44 object-contain group-hover:grayscale"
//               style={{ userSelect: "none", pointerEvents: "none" }}
//             />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default SkillsMarquee;


import React, { useRef, useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import "./SkillsMarquee.css";
import python from "../../assets/yellow/pythono.png";
import java from "../../assets/yellow/javao.png";
import javascript from "../../assets/yellow/javascripto.png";
import cppo from "../../assets/yellow/cppo.png";
import sqlo from "../../assets/yellow/sqlo.png";
import css3 from "../../assets/yellow/csso.png";
import awso from "../../assets/yellow/awso.png";
import git from "../../assets/yellow/gito.png";
import django from "../../assets/yellow/djangoo.png";
import numpy from "../../assets/yellow/numpyo.png";
import pandas from "../../assets/yellow/pandaso.png";
import opencv from "../../assets/yellow/opencvo.png";
import discord from "../../assets/yellow/discordo.png";
import mysqlo from "../../assets/yellow/mysqlo.png";
import postgreso from "../../assets/yellow/postgreso.png";
import tensorflow from "../../assets/yellow/tensorflowo.png";
import keras from "../../assets/yellow/keraso.png";
import pytorch from "../../assets/yellow/pytorcho.png";
import jupyter from "../../assets/yellow/jupytero.png";
import rlang from "../../assets/yellow/rlango.png";

const skillsData = [
  { id: 1, image: python, title: "Python" },
  { id: 2, image: cppo, title: "C/C++" },
  { id: 3, image: java, title: "Java" },
  { id: 4, image: rlang, title: "R" },
  { id: 5, image: sqlo, title: "SQL" },
  { id: 6, image: awso, title: "AWS" },
  { id: 7, image: css3, title: "CSS" },
  { id: 8, image: git, title: "Git" },
  { id: 9, image: javascript, title: "JavaScript" },
  { id: 10, image: django, title: "Django" },
  { id: 11, image: numpy, title: "NumPy" },
  { id: 12, image: pandas, title: "Pandas" },
  { id: 13, image: opencv, title: "OpenCV" },
  { id: 14, image: discord, title: "Discord" },
  { id: 15, image: mysqlo, title: "MySQL" },
  { id: 16, image: postgreso, title: "PostgreSQL" },
  { id: 17, image: tensorflow, title: "TensorFlow" },
  { id: 18, image: pytorch, title: "PyTorch" },
  { id: 19, image: jupyter, title: "Jupyter" },
];

function SkillsMarquee() {
  const marqueeContainerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setStartX(event.pageX - marqueeContainerRef.current.offsetLeft);
    setScrollLeft(marqueeContainerRef.current.scrollLeft);
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;
    const x = event.pageX - marqueeContainerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    marqueeContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => setIsDragging(false);

  useEffect(() => {
    const container = marqueeContainerRef.current;
    let animationFrameId;
    
    const autoScroll = () => {
      container.scrollLeft += .5;
      if (container.scrollLeft >= container.scrollWidth / 2) {
        container.scrollLeft = 0;
      }
      animationFrameId = requestAnimationFrame(autoScroll);
    };
    
    animationFrameId = requestAnimationFrame(autoScroll);
    return () => cancelAnimationFrame(animationFrameId);
  }, []);

  return (
    <div
      ref={marqueeContainerRef}
      className="relative flex overflow-x-hidden marquee-container"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <div className="py-5 flex animate-marquee whitespace-nowrap">
        {skillsData.map(({ id, image, title }) => (
          <div key={id} className="skill--box flex justify-start p-3 shadow-lg rounded-xl object-cover w-[4.5rem] h-[4.5rem] md:w-20 md:h-20 bg-gray-100 dark:bg-neutral-900">
            <img src={image} alt={title} className="w-36 h-36 md:h-44 md:w-44 object-contain" />
          </div>
        ))}
      </div>
      <div className="absolute flex top-0 py-5 animate-marquee2 whitespace-nowrap">
        {skillsData.map(({ id, image, title }) => (
          <div key={id} className="skill--box flex justify-start p-3 shadow-lg rounded-xl object-cover w-[4.5rem] h-[4.5rem] md:w-20 md:h-20 bg-gray-100 dark:bg-neutral-900">
            <img src={image} alt={title} className="w-36 h-36 md:h-44 md:w-44 object-contain" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default SkillsMarquee;
