import { motion } from "framer-motion";
import { FaGithub, FaLinkedin, FaEnvelope } from "react-icons/fa";

const animation = {
  hide: { x: -8, opacity: 0 },
  show: {
    x: 0,
    opacity: 1,
  },
};

function HeaderTechStack() {
  return (
    <div className="flex flex-col items-center justify-center">
      <motion.p
        className="mb-2.5 text-sm md:text-xl font-semibold text-[#7000ff] dark:text-[#7000ff]"
        initial={animation.hide}
        animate={animation.show}
        transition={{ delay: 0.6 }}
      >
        {/* My Links: */}
      </motion.p>
      <motion.ul
        className="flex items-center gap-3.5 text-slate-600 dark:text-slate-300"
        initial="hide"
        animate="show"
        transition={{ delayChildren: 0.6, staggerChildren: 0.025 }}
      >
        <motion.li variants={animation}>
          <a
            href="https://github.com/VanshajR"
            target="_blank"
            rel="noopener noreferrer"
            className="transition duration-200 hover:text-gray-900 dark:hover:text-white"
          >
            <FaGithub className="h-5 w-5 md:h-7 md:w-7" />
          </a>
        </motion.li>
        <motion.li variants={animation}>
          <div className="h-3 md:h-5 w-[1px] bg-slate-400 dark:bg-neutral-700" />
        </motion.li>
        <motion.li variants={animation}>
          <a
            href="https://linkedin.com/in/vanshajraghuvanshi"
            target="_blank"
            rel="noopener noreferrer"
            className="transition duration-200 hover:text-[#0077B5]"
          >
            <FaLinkedin className="h-5 w-5 md:h-7 md:w-7" />
          </a>
        </motion.li>
        <motion.li variants={animation}>
          <div className="h-3 md:h-5 w-[1px] bg-slate-400 dark:bg-neutral-700" />
        </motion.li>
        <motion.li variants={animation}>
          <a
            href="mailto:vanshajraghuvanshi@example.com"
            className="transition duration-200 hover:text-[#D44638]"
          >
            <FaEnvelope className="h-5 w-5 md:h-7 md:w-7" />
          </a>
        </motion.li>
      </motion.ul>
      <motion.p
        className="mb-2.5 mt-3.5 text-sm md:text-xl font-semibold text-[#db6b31] dark:text-[#db6b31]"
        initial={animation.hide}
        animate={animation.show}
        transition={{ delay: 0.6 }}
      >
        PS: Double click/tap the logo for a fun toggle!
      </motion.p>
    </div>
  );
}

export default HeaderTechStack;
